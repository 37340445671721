// @import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'MuseoSlab';
    src: url('../../assets/font/MuseoSlab-700.eot?#iefix') format('embedded-opentype'),  url('../../assets/font/MuseoSlab-700.otf')  format('opentype'),
    url('../../assets/font/MuseoSlab-700.woff') format('woff'), url('../../assets/font/MuseoSlab-700.ttf')  format('truetype'), url('../../assets/font/MuseoSlab-700.svg#MuseoSlab-700') format('svg');
    font-weight: 700;
    font-style: 700;
}
@font-face {
    font-family: 'MuseoSlab';
    src: url('../../assets/font/MuseoSlab-300.eot?#iefix') format('embedded-opentype'),  url('../../assets/font/MuseoSlab-300.otf')  format('opentype'),
    url('../../assets/font/MuseoSlab-300.woff') format('woff'), url('../../assets/font/MuseoSlab-300.ttf')  format('truetype'), url('../../assets/font/MuseoSlab-300.svg#MuseoSlab-300') format('svg');
    font-weight: normal;
    font-style: normal;
}

// body{
//     overflow: auto !important;
// }

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
// }

.site {
    overflow: auto;
    color: #525252;
    font-family: 'MuseoSlab';
    font-size: 18px;
    line-height: 1.2;
    height: 100vh;
    
    img {
        max-width: 100%;
    }

    section {
        padding-top: 50px;
        padding-bottom: 50px;
        display: flex;
        justify-content: center;
    }

    .wrapper {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;

        .fromContact{
            height: 100%;

            form{
                .float-container {
                    border: solid 1px #dadada;
                    padding: 0 8px;
                    position: relative;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  .float-container input {
                    border: none;
                    font-size: 14px;
                    margin: 16px 0 10px;
                    outline: 0;
                    width: 100%;
                  }
                  label {
                    font-size: 16px;
                    position: absolute;
                    transform-origin: top left;
                    transform: translate(0, 16px) scale(1);
                    transition: all .1s ease-in-out;
                    
                  }
                  .float-container.active label {
                    transform: translate(0, 4px) scale(.75);
                  }
                  #message{
                    width: 100%;
                    height: 220px;
                    border: none;
                    padding-top: 30px;
                  }
                  .errorFieds{
                    height: 25px;
                    margin-bottom: 5px;
                    color: red;
                    font-size: 0.8rem;
                  }
                  .buttonContact{
                    float: right;
                    border: none;
                    background-color: #003159;
                    color: white;
                    padding: 0.5em;
                    border-radius: 5px;
                    font-weight: 600;
                  }
            }

        }
    }
    
    .has-bg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        color: #FFF;
        b {
            color: #7bc4ed;
            font-weight: 700;
        }
    }

    b {
        font-weight: 700;
    }

    .d-flex {
        display: flex !important;
    }
    
    .justify-content-center {
        justify-content: center;
    }

    .align-items-right{
        margin-right: 0px;
        text-align: right;
    }
    
    .align-items-center {
        align-items: center;
    }
    
    .row {
        display: flex;
    }

    .logo {
        max-width: 500px;
    }

    .top-section {
        background-image: url(../../assets/images/old/top-section.jpg);
    }

    .screen-section {
        background-image: url(../../assets/images/old/screen-section.jpg);
    }

    .top-section .col-md-12 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    .box-section {
        color: #10488a;
    }

    .footer-section {        
        .wrapper {
            align-items: flex-start;
            // max-width: 1110px;
        }
        .col-md-6:first-child {
            padding-right: 50px;
        }

        .col-md-6:last-child {
            border-left: 1px solid #63b3f7;
            padding-left: 50px;
        }


    }

    .bg-footer{
        /* Style for "Rectangle" */
        // height: 350px;
        background-color: #003159;
        .logo{
            max-width: 100%;
        }

        h4{
            color: #FFF;
            font-size: 1em;
            letter-spacing: -1px;
            font-weight: 600;
        }

        .poweredby {
            display: flex;
            align-items: center;
    
            span{
                color: #FFF;
                // margin-top: 20px;
                font-weight: 500;
            }
    
            img {
                margin-top: -20px;
                margin-left: 20px;
                max-height: 90px;
            }
        }
    }

    .social-logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            max-width: 22%;
        }

        opacity: 0.8;
    }

    .block {
        border-bottom: 1px solid #63b3f7;
        padding-bottom: 20px;
        padding-top: 30px;
        line-height: 1.4;
        font-size: 18px;
        font-weight: 600;

        &:last-child {
            border-bottom: 0;
        }
        
        .title {
            color: #10488a;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    
    .product-box {
        margin: -100px 0 -60px -160px;
        max-width: calc(100% + 120px);
    }

    .big-border {
        border-left: 40px solid #89baf6;
        padding-left: 40px;

        h2 {
            font-size: 1.9em;
            margin-top: 0;
        }

        ul {
            margin: 20px 0 0 0;
            padding: 0 0 0 20px;
            font-size: 1.4em;
            font-weight: 500;
        }

        li {
            list-style: disc;
            padding: 10px 0;
            b{
                font-weight: 600;
            }
        }
    }

    .video {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            border: 5px solid #FFF;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .login {
        display: flex;
        align-items: flex-start;
        margin: 0;
        justify-content: flex-end;
    }

    .input-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: auto;

        input {
            border: 1px solid rgba(255,255,255,.4);
            padding: 10px;
            background: transparent;
            color: #FFF;
            height: 38px;
            width: 170px;
            font-size: 13px;

            &:focus {
                border: 1px solid rgba(255,255,255,.8);
                outline: none;
            }
        }

        a {
            text-decoration: none;
            color: rgba(255,255,255,.4);
            font-size: 12px;
            padding: 5px 10px;
        }
    }

    .login {
        .input-group {
            margin-left: -1px;

            button {
                border-radius: 0 5px 5px 0;
                appearance: none !important;
                -webkit-appearance: none !important;
                border: 1px solid rgba(255,255,255,.4);
                padding: 0;
                background: transparent;
                color: #FFF;
                width: 37px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-content: center;
                
            }
        }

        .input-group:first-child input {
            border-radius: 5px 0 0 5px;
        }

        img {
            max-width: 40px;
            margin-right: 20px;
        }
    }

    .login-session {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn:first-child {
            margin-left: 20px;
        }

        .btn {
            margin-left: 10px;
            box-shadow: none !important;
        }
    }

    .phone {
        color: #005faa;
        font-size: 16px;

        a {
            font-weight: 700;
            color: #005faa;
        }

        .number {
            font-weight: 700;
        }
    }

    .features p {
        font-size: 22px;
    }

    @media screen AND (max-width: 640px) {    
        .logo {
            max-width: 90%;
        }

        section {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .wrapper {
            padding: 20px;
        }

        .product-box {
            max-width: 100%;
            margin: 0;
        }

        .big-border {
            border: 0;
            padding-left: 15px;
        }

        .footer-section .col-md-6:last-child {
            margin-top: 20px;
            border-left: none;
            padding-left: 0px;
            padding-right: 40px;
        }

        .poweredby {
            flex-wrap: wrap;
            * {
                margin-top: 20px;
            }
        }
        
        .input-group input {
            max-width: 150px;
            width: 150px;
        }

        .login {
            margin-top: 30px;
            justify-content: center;

            img {
                display: none;
            }
        }

        .logo-container {
            text-align: center;
        }

        .login-session {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            .btn:first-child {
                margin-left: 20px;
            }
    
            .btn {
                margin-left: 10px;
                box-shadow: none !important;
            }
        }
    }
}
